<template lang='pug'>
	.content
</template>

<script>
export default {
	metaInfo: {
		title: 'Safe.is - Driving in Iceland.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/summer.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'I took a quiz on Safe.is.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Oh dear, You need more preparation, but not to worry, you can find everything you need to know about travelling safely in Iceland here on the webpage. After a thorough reading, retake the test.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Oh dear, You need more preparation, but not to worry, you can find everything you need to know about travelling safely in Iceland here on the webpage. After a thorough reading, retake the test.'
			}
		]
	},
	mounted: function () {
		this.$nextTick(() => {
			setTimeout(() => {
				this.$router.push('/');
			}, 300);
		});
	}
};
</script>

<style>

</style>
